const homeJsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "address": {
        "@type": "PostalAddress",
        "addressLocality": "Malta",
        "postalCode": "SPB9061",
        "streetAddress": "Hudson Malta Sales Ltd, Hudson House, Burmarrad Road, Burmarrad",
        "contactPoint": [
            {
                "@type": "ContactPoint",
                "telephone": "+35623459001",
                "contactType": "customerservice"
            }
        ]
    },
    "url": "https://www.hudsonstore.com/",
    "email": "customercare@hudsonstore.com",
    "name": "Hudson Store",
    "logo": "https://www.hudsonstore.com/_ipx/_/hudson/logo.png",
    "telephone": "+35623459001"
}

export { homeJsonLd }