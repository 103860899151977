









































import {
  SfLink,
  SfIcon,
  SfImage,
  SfModal,
  SfButton,
  SfInput,
  SfHeading,
} from "@storefront-ui/vue";
import { addBasePath } from "~/helpers/addBasePath";
import {
  computed,
  ref,
  defineComponent,
  useContext,
} from "@nuxtjs/composition-api";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { useNewsletter, useUiNotification } from "~/composables";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("email", {
  ...email,
  message: "Invalid email",
});

export default defineComponent({
  name: "NewsLetterSubscriptionModal",
  components: {
    SfLink,
    SfIcon,
    SfImage,
    SfModal,
    SfButton,
    SfInput,
    SfHeading,
    ValidationObserver,
    ValidationProvider,
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const { updateSubscription, error } = useNewsletter();
    const { send: sendNotification, notifications } = useUiNotification();
    const { app } = useContext();

    const email = ref("");

    const closeModal = () => {
      emit("closeModal");
    };

    const handleSubmitClick = () => async () => {
      // reset();
      const { data, errors } = await updateSubscription({ email: email.value });

      if (
        data?.subscribeEmailToNewsletter?.status?.toUpperCase() === "SUBSCRIBED"
      ) {
        sendNotification({
          id: Symbol("product_removed"),
          message: app.i18n.t(
            "successfully Subscribed to newsletter"
          ) as string,
          type: "success",
          icon: "check",
          persist: false,
          title: "Newsletter Subscription",
        });
      } else if (errors) {
        sendNotification({
          id: Symbol("product_removed"),
          message: app.i18n.t(errors[0].message) as string,
          type: "danger",
          icon: "error",
          persist: false,
          title: "Newsletter Subscription",
        });
      }
    };
    return {
      addBasePath,
      closeModal,
      email,
      handleSubmitClick,
    };
  },
});
